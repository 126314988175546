import 'ol/ol.css';
import 'ol-ishare/oli.css';
import 'mk-toast/dist/mk-toast.css';
import './index.css';

import {debounce} from 'lodash-es';
import copy from 'copy-to-clipboard';
import mktoast from 'mk-toast';
import Plausible from 'plausible-tracker';

import TileLayer from 'ol/layer/Tile';
import {OverviewMap, ScaleLine, defaults as defaultControls} from 'ol/control';
import Attribution from 'ol/control/Attribution';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import {equalTo, or} from 'ol/format/filter';
import WKT from 'ol/format/WKT';
import View from 'ol/View';
import ImageWMS from 'ol/source/ImageWMS';
import {writeFilter} from 'ol/format/WFS';
import OSM from 'ol/source/OSM';
import {Image as ImageLayer} from 'ol/layer';
import { never, pointerMove, click, singleClick } from 'ol/events/condition';
import { Circle, Fill, Stroke, Style, Text } from 'ol/style';
import {unByKey} from 'ol/Observable';
import Select from 'ol/interaction/Select';
import {boundingExtent} from 'ol/extent';
import Collection from 'ol/Collection';
import Draw from 'ol/interaction/Draw';

import {LiteMap} from 'ol-ishare/litemap';
import {LayerSwitcher as OliLayerSwitcher} from 'ol-ishare/control/layerswitcher';
import {HistoryNavigation} from 'ol-ishare/control/historynavigation';
import {getBaseLayers, getCurrentBaseLayer, getLayerByName, findLayers, getGroupContainingLayer} from 'ol-ishare/olutil';
import {Gazetteer} from 'ol-ishare/control/gazetteer';
import {LayerSearch} from 'ol-ishare/search';
import {createView, createVectorLayer} from 'ol-ishare/map';
import {getInfoAtPoint, infoResultToHtml} from 'ol-ishare/info';
import {Measure as MeasureInteraction, generateMeasurementText} from 'ol-ishare/interaction/measure';
import {QueryString as QueryStringStore, WebStorage} from 'ol-ishare/store';
import {LiteMap as LiteMapState, State} from 'ol-ishare/state';
import {resolutionToScale} from 'ol-ishare/view';

import OlLayerSwitcher from 'ol-layerswitcher';

import {WelcomePanel} from './welcomepanel.js';
import {ReportPanel} from './reportpanel.js';
import {MeasurePanel} from './measurepanel.js';
import {LayerInfoPanel} from './layerInfoPanel.js';
import { createGazSource } from './gazetteer.js';
import {Button} from './button.js';
import {LinkButton} from './linkbutton.js';
import { Panel } from './panel.js';
import { formatArea, formatHeatDemand, roundTo } from './formatting.js';

const pageUrl = new URL(window.location);

export const iShareUrl = pageUrl.searchParams.get('ishareurl') || '../../';

// Handle the profile query string value which may or may not be double quoted.
// Once read or set to a default set the pageUrl profile value to a double quoted
// version so that QueryStringStore is happy; it expects all values to be valid JSON.
const profile = (pageUrl.searchParams.get('profile') || 'mapsources/heatmap').replace(/"/g, '');
pageUrl.searchParams.set('profile', '"' + profile + '"');

// Track LiteMap state, saving to a query string store (to provide a
// "Share this map" link and "Contact support link")
const urlStore = new QueryStringStore({ baseUrl: pageUrl });
const webStore = new WebStorage();
const state = new LiteMapState([webStore, urlStore]);

const controls = defaultControls({rotate: false});
const interactions = LiteMap.defaultInteractions({altShiftDragRotate: false, dragRotate: false, pinchRotate: false});

 const {trackPageview} = Plausible({
  domain: 'heatmap.data.gov.scot',
  // trackLocalhost: true
});

let liteOptions = {
  target: 'map',
  iShareUrl: iShareUrl,
  profile: profile,
  layers: [
      //Heat Demand
      { name: 'heat_demand', visible: true, opacity: 0.5},
      { name: 'Datazone_Heat_Demand', visible: false, opacity: 0.5 },
      { name: 'Settlements_Heat_Demand', visible: false, opacity: 0.5 },
      { name: 'Local_Authority_Demand', visible: false, opacity: 0.5 },
      { name: 'hmp_1km', visible: false, opacity: 0.5 },
      { name: 'hmp_500m', visible: false, opacity: 0.5 },
      { name: 'hmp_250m', visible: false, opacity: 0.5 },
      { name: 'hmp_50m', visible: false, opacity: 0.5 },
      //Heat Networks
      { name: 'Heat_Networks_1', visible: false },
      //Boundaries
      { name: 'Datazone', visible: false },
      { name: 'Local_Authority', visible: false },
      { name: 'Settlement', visible: false },
      //Tenure
      { name: 'Social_Rent_Datazone_1', visible: false, opacity: 0.5 },
      //Geothermal and Mines
      { name: 'Buried_HHP_Granites', visible: false, opacity: 0.5 },
      { name: 'Depth_Mine_Workings', visible: false },
      { name: 'Hot_SED_Aquifers', visible: false, opacity: 0.5 },
      { name: 'mine_entries', visible: false },
      { name: 'reporting_areas', visible: false },
      { name: 'dev_risk_area', visible: false },
      { name: 'coal_resource_area', visible: false },
      { name: 'surface_mining', visible: false },
      //Energy Supply
      { name: 'AnaerobicDigestion', visible: false },
      { name: 'Biomass', visible: false },
      { name: 'EnergyFromWaste', visible: false },
      { name: 'FossilFuel', visible: false },
      { name: 'GroundSourceHeatPump', visible: false },
      { name: 'Hydro', visible: false },
      { name: 'LandfillGas', visible: false },
      { name: 'Nuclear', visible: false },
      { name: 'Other', visible: false },
      { name: 'SolarPhotovoltaic', visible: false },
      { name: 'TidalStream', visible: false },
      { name: 'WaterSourceHeatPump', visible: false },
      { name: 'WavePower', visible: false },
      { name: 'Wind', visible: false },
      //Heat Demand Confidence
      { name: 'conf_1km', visible: false, opacity: 0.5 },
      { name: 'conf_500m', visible: false, opacity: 0.5 },
      { name: 'conf_250m', visible: false, opacity: 0.5 },
      { name: 'conf_50m', visible: false, opacity: 0.5 },
  ],
  view: {
      easting: 222410,
      northing: 883409,
      zoom: 3265513
  }
};
var lite;
let map = document.querySelector('#map');
let sidebar = document.querySelector('#sidebar');

map.classList.add('sidebarOpen');


// Load any saved state from the query string and local storage

Promise.all([
  state.updateFromStore(urlStore),
  state.updateFromStore(webStore),
]).then(([urlState, webState]) => {

    let urlStateHasValues = state.keys.reduce((hasValues, key) => {
      // Skip the profile key
      if (key === 'profile') return hasValues;
      // If the urlState has a key defined then we've got values
      if (urlState[key]) {
        return true;
      }
      return hasValues;
    }, false);

    if (urlStateHasValues) {
      liteOptions = State.overrideObjects(liteOptions, urlState);
    } else {
      liteOptions = State.overrideObjects(liteOptions, webState);
    }
    
    liteOptions.map = {
      controls: controls,
      interactions: interactions
  },


    lite = new LiteMap(liteOptions);

    state.watch(lite);

    lite.on('load', function (evt) {

        // Get a reference to the InfoPopup interaction which is
        // required for customising info results for heat demand
        // raster layers and disabling info on click when creating
        // a custom report
        let infoClick = lite.map.getInteractions().getArray().find(
            interaction => interaction.popupId_ == "iShare:InfoPopup"
        );

        // HEAT DEMAND RASTER INFO

        infoClick.infoResultsToHtml = (errs, infoLayers, featureCollections) => {
          let html = featureCollections
            .map(function (features, index) {
              let infoLayer = infoLayers[index];
              let err = errs[index];
              if (infoLayer.get('iShare:config').layerName == 'heat_demand') {
                var rsl = lite.map.getView().getResolution();
                var scale = resolutionToScale(rsl);
                if (scale >= 100000) {
                    infoLayer = getLayerByName(lite.map.getLayerGroup(), 'hmp_1km');
                } else if (scale < 100000 && scale >= 25000) {
                    infoLayer = getLayerByName(lite.map.getLayerGroup(), 'hmp_500m');
                } else if (scale < 25000 && scale >= 10000) {
                    infoLayer = getLayerByName(lite.map.getLayerGroup(), 'hmp_250m');
                } else {
                    infoLayer = getLayerByName(lite.map.getLayerGroup(), 'hmp_50m');
                }
              }
              if (
                infoLayer.get('iShare:config').layerName.startsWith('hmp_') &&
                features.length
              ) {
                let layerConfig = infoLayer.get('iShare:config');
                let html =
                  '<div> \
                            <h3>' + layerConfig.displayName + '</h3>';
                html += features.reduce((html, feature) => {
                  let heatdemand = feature.get('value_0');
                  heatdemand = formatHeatDemand(heatdemand);
                  html +=
                    '<div class="feature"> \
                            <p> \
                              <strong class="column">Heat demand</strong> \
                              <span class="value">' +
                    heatdemand +
                    '</span> \
                            </p> \
                          </div>';
                  return html;
                }, '');
                html += '</div>';
                return html;
              }
              if (
                infoLayer.get('iShare:config').layerName.startsWith('conf_') &&
                features.length
              ) {
                let layerConfig = infoLayer.get('iShare:config');
                let html =
                  '<div> \
                            <h3>' + layerConfig.displayName + '</h3>';
                html += features.reduce((html, feature) => {
                  let confidence = feature.get('value_0');
                  confidence = roundTo(confidence, 1);
                  html +=
                    '<div class="feature"> \
                            <p> \
                              <strong class="column">Confidence</strong> \
                              <span class="value">' +
                    confidence +
                    '</span> \
                            </p> \
                          </div>';
                  return html;
                }, '');
                html += '</div>';
                return html;
              }
              return customInfoResultToHtml(err, infoLayer, features);
            })
            .join('\n');
          return html;
        };
        
        let customInfoResultToHtml = (err, infoLayer, features) => {
          var layerConfig = infoLayer.get('iShare:config');
          var inFrame = window.self !== window.top;

          if (layerConfig.infoTemplate) {
            if (window.nunjucks) {// All good we've got nunjucks available to render the templates
            } else {
              console.info('Found custom info templates but nunjucks was not found. To enable custom info templates load nunjucks via a script tag.');
            }
          }

          var html = '';

          if (layerConfig.infoClick) {
            if (layerConfig.infoTemplate && window.nunjucks) {
              html = window.nunjucks.renderString(layerConfig.infoTemplate, {
                layerConfig: layerConfig,
                features: features,
                error: err
              });
            } else {
              var featuresHtml = '';
              featuresHtml = features.map(function (feature) {
                var html = '<div class="feature">';
                html += layerConfig.fields.map(function (field, idx) {
                  var val = feature.get(field.name); // If the column value is null or undefined skip it

                  if (val === null || val === undefined) {
                    return '';
                  }
                  if (field.displayName == 'Number of Energy Supply Points' ||
                      field.displayName == 'Public-sector demand') {
                      return '';
                  }

                  var url = null;

                  if (field.link) {
                    if (field.link.baseUrl) {
                      url = field.link.baseUrl.replace('{0}', feature.get(field.link.value));
                    } else {
                      url = feature.get(field.link.value);
                    }
                  }

                  var column = '';

                  if (field.displayName) {
                    column = '<strong class="column">' + field.displayName + '</strong> ';
                  }
                  
                  var fieldValue = feature.get(field.name);
                  
                  if (field.displayName == "Area") {
                      fieldValue = formatArea(fieldValue, layerConfig.layerName);
                  }
                  if (field.displayName == "Demand") { 
                      fieldValue = formatHeatDemand(fieldValue);
                  }
                  if (field.displayName == "Demand density") {
                      fieldValue = `${formatHeatDemand(fieldValue)}/m&sup2;`;
                  }
                  if (field.displayName == "% socially renting") {
                      fieldValue = roundTo(fieldValue, 2);
                  }

                  return '<p>' + column + '<span class="value' + (idx === 0 ? ' first' : '') + '">' + (url ? '<a href="' + url + '"' + (inFrame ? ' target="_new"' : '') + '>' : '') + fieldValue + (url ? '</a>' : '') + '</span>' + '</p>';
                }).join('\n');
                html += '</div>';
                return html;
              }).join('\n');

              if (err) {
                featuresHtml += '<div class="error">' + err.message + '</div>';
              }

              if (featuresHtml.length) {
                html += '<div class="layer"><h3>' + layerConfig.displayName + '</h3>';
                html += featuresHtml;
                html += '</div>';
              }
            }
          }

          return html;
        }


        let gridLayers = findLayers(lite.map.getLayerGroup(), (lyr) => {
          return (
            lyr.get('iShare:config') &&
            lyr.get('iShare:config').layerName &&
            (lyr.get('iShare:config').layerName.startsWith('hmp_') ||
             lyr.get('iShare:config').layerName.startsWith('conf_'))
          );
        });

        gridLayers.forEach(lyr => {
          // Ensure we only ever get info for the cell in which the click falls
          // the downside of this is that the user has to be accurate when clicking
          lyr.get('iShare:config').infoTolerance = {
            tolerance: 0,
            toleranceUnits: 'METERS'
          };
        });

        let supplyLayers = findLayers(lite.map.getLayerGroup(), (lyr) => {
          return (
            getGroupContainingLayer(lite.map.getLayerGroup(), lyr).get('title' ) == "Energy Supply"
          );
        });

        supplyLayers.forEach(lyr => {
          // Ensure we only ever get info for the cell in which the click falls
          // the downside of this is that the user has to be accurate when clicking
          lyr.get('iShare:config').infoTolerance = {
            tolerance: 10,
            toleranceUnits: 'PIXELS'
          };
        });

        lite.map.getView().addEventListener('change:resolution', debounce(zoomDemand, 300));
        var oldZoom = lite.map.getView().getResolution();
        function zoomDemand() {
            var lyr = getLayerByName(lite.map.getLayerGroup(), 'heat_demand');
            var newZoom = lite.map.getView().getResolution();
            console.log(oldZoom, newZoom);
            var oldScale = resolutionToScale(oldZoom);
            var newScale = resolutionToScale(newZoom);
            if (newScale >= 100000 && oldScale < 100000) {
                switchDemand(lyr, 'hmp_1km', 'Heat Demand (autoscale) 1km grid');
            } else if (newScale < 100000 && newScale >= 25000 && (oldScale >= 100000 || oldScale < 25000)) {
                switchDemand(lyr, 'hmp_500m', 'Heat Demand (autoscale) 500m grid');
            } else if (newScale < 25000 && newScale >= 10000 && (oldScale >= 25000 || oldScale < 10000)) {
                switchDemand(lyr, 'hmp_250m', 'Heat Demand (autoscale) 250m grid');
            } else if (newScale < 10000 && oldScale >= 10000) {
                switchDemand(lyr, 'hmp_50m', 'Heat Demand (autoscale) 50m grid');
            }
            oldZoom = lite.map.getView().getResolution();
        }
        function switchDemand(lyr, targetName, lyrTitle) {
            var newLyr = getLayerByName(lite.map.getLayerGroup(), targetName);
            var newSrc = newLyr.getSource();
            lyr.setSource(newSrc);
            lyr.set('title', lyrTitle);
            try {
                document.querySelector('#heat_demandLegend').parentNode.parentNode.parentNode.querySelector('label').innerText = lyrTitle;
                var panel = document.querySelector('.layerInfoPanel');
                LayerInfoPanel.showLayerInfo(lyr, lite.map, panel, false, newLyr);
            } catch(err) {
            }
            lyr.getSource().refresh();
            layerControl.renderPanel();
            try {
                document.querySelector('.ol-popup').style.display = 'none';
            } catch (e) {};



 
            var opacitySlider = document.querySelector('.opacitySlider');
            try {
                var sliderParent = opacitySlider.parentNode;
            } catch(err) {
                console.log('Slider not visible');
                return lyr;
            }
            sliderParent.removeChild(opacitySlider);

            var opacitySlider = document.createElement('input');
            opacitySlider.type = 'range';
            opacitySlider.setAttribute('max', 1);
            opacitySlider.setAttribute('step', 0.01);
            opacitySlider.classList.add('opacitySlider');

            var opacityDisplay = document.querySelector('#opacityDisplay');

            sliderParent.insertBefore(opacitySlider, opacityDisplay);

            opacitySlider.value = lyr.getOpacity();
            opacityDisplay.value = `${Math.round(opacitySlider.valueAsNumber * 100)}%`;
            opacitySlider.addEventListener('input', function() {
                opacityDisplay.value = `${Math.round(opacitySlider.valueAsNumber * 100)}%`;
                lyr.setOpacity(opacitySlider.valueAsNumber);
                try {
                    document.querySelector(`#lgnd_${lyr.get('iShare:layerName')}`).style.opacity = opacitySlider.valueAsNumber;
                } catch(err) {
                }
                try {
                    document.querySelector(`#pnl_lgnd_${newLyr.get('iShare:layerName')}`).style.opacity = opacitySlider.valueAsNumber;
                } catch(err) {
                }
            }, false);
            return lyr;
        }
        
        // BGS ATTRIBUTION
        let bgsAttr = 'Contains British Geological Survey materials &copy; UKRI 2024';
        getLayerByName(lite.map.getLayerGroup(), 'Buried_HHP_Granites').getSource().setAttributions(bgsAttr);
        getLayerByName(lite.map.getLayerGroup(), 'Depth_Mine_Workings').getSource().setAttributions(bgsAttr);
        getLayerByName(lite.map.getLayerGroup(), 'Hot_SED_Aquifers').getSource().setAttributions(bgsAttr);
        
        // BGS SCALE-DEPENDENT visibility
        getLayerByName(lite.map.getLayerGroup(), 'dev_risk_area').setMaxResolution(40);
        getLayerByName(lite.map.getLayerGroup(), 'coal_resource_area').setMaxResolution(40);
        getLayerByName(lite.map.getLayerGroup(), 'mine_entries').setMaxResolution(15);
        getLayerByName(lite.map.getLayerGroup(), 'surface_mining').setMaxResolution(15);
        getLayerByName(lite.map.getLayerGroup(), 'dev_risk_area').setMinResolution(5);
        getLayerByName(lite.map.getLayerGroup(), 'coal_resource_area').setMinResolution(5);
        getLayerByName(lite.map.getLayerGroup(), 'mine_entries').setMinResolution(5);
        getLayerByName(lite.map.getLayerGroup(), 'surface_mining').setMinResolution(5);

        // MOBILE SIDEBAR BUTTON
        var body = document.querySelector('body');
        var sidebarButton = document.createElement('button');
        sidebarButton.id = 'sidebarButton';
        sidebarButton.innerHTML = "menu";
        sidebarButton.addEventListener('click', function(evt) {
            sidebar.classList.add('sidebarOpen');
            map.classList.add('sidebarOpen');
            lite.map.updateSize();
        })
        body.appendChild(sidebarButton);

        // SIDEBAR CLOSER
        var sidebarCloser = document.createElement('button');
        sidebarCloser.id = 'sidebarCloser';
        sidebarCloser.innerHTML = "&times;";
        sidebarCloser.addEventListener('click', function(evt) {
            sidebar.classList.remove('sidebarOpen');
            map.classList.remove('sidebarOpen');
            lite.map.updateSize();
            sidebarButton.classList.add('active');
        })
        sidebar.appendChild(sidebarCloser);

        // LAYER SWITCHER (CUSTOMIZED)
        var layerControl = new OliLayerSwitcher({
            reverse: true,
            activationMode: 'click',
            startActive: true,
            legends: 'dynamic',
            groupSelectStyle: 'group',
            target: sidebar
        })
        lite.map.getLayerGroup().getLayers().forEach(
            function(el,idx,arr) {
                if (el.get('title') != 'Heat Demand' && el.get('title') != 'Base maps') {
                    el.set('fold', 'close');
                } else {
                    el.set('fold', 'open');
                }
                el.getLayers().forEach(function(lyr) {
                    lyr.on("change:visible", function(e) {
                        lyr.get('iShare:layerName')
                        if (lyr.getVisible()) {
                            trackPageview({url: `https://heatmap.data.gov.scot/layer/${lyr.get('iShare:layerName')}`});
                        }
                    });
                })
            }
        );
        lite.map.addControl(layerControl);
        zoomDemand();
        
        // BUTTON BAR
        var buttonBar = document.createElement('div');
        buttonBar.id = 'buttonBar';
        sidebar.appendChild(buttonBar);

        // LAYER INFO PANEL
        var layerInfo = new LayerInfoPanel({
            target: document.querySelector('.layer-switcher')
        });
        lite.map.addControl(layerInfo);

        // GAZETTEER
        var gazetteer = new Gazetteer({
            iShareUrl: iShareUrl,
            profile: profile,
            placeholder: 'Search place or Data Zone',
            target: sidebar,
            sources: [
                createGazSource(),
                new LayerSearch({
                    iShareUrl: iShareUrl,
                    profile: profile,
                    layerName: 'Datazone',
                    searchField: 'datazone',
                    srs: 'EPSG:27700',
                    maxFeatures: 5
                })
            ]
        });
        gazetteer.on('select', function (evt) {
            lite.map.getView().fit( 
                evt.result.bbox,
                {duration: 1000}
            );
        });
        lite.map.addControl(gazetteer);

        // OVERVIEW
        var overviewMapControl = new OverviewMap({
            layers: [
                new TileLayer({
                    source: new OSM(),
                })
            ],
            view: new View({
                projection: 'EPSG:27700',
                resolutions: [5000, 2500, 1250, 625, 312, 156]
            }),
            collapsible: false
        });
        lite.map.addControl(overviewMapControl);

        // SCALEBAR
        var scalebar = new ScaleLine({bar: true});
        lite.map.addControl(scalebar);
        
        // WELCOME PANEL
        var welcomePanel = new WelcomePanel({
            target: sidebar
        });
        welcomePanel.on('close', function (evt) {
            this.hide();
        })

        lite.map.addControl(welcomePanel);


        //// REPORT
      
        // SELECTION LAYER
        const reportLayer = new VectorLayer({
          layerName: 'reportLayer',
          source: new VectorSource({ wrapX: false }),
          zIndex: 250,
          style: new Style({
            stroke: new Stroke({
                color: '#0065bd',
                width: 3
            }),
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.25)'
            })
          })
        });
        lite.map.addLayer(reportLayer);

        let reportRasterLayer = new ImageLayer({
            source: new ImageWMS({
                url: getLayerByName(
                    lite.map.getLayerGroup(),   
                    'Settlements_Heat_Demand'
                ).getSource().getUrl().replace('/heatmap', '/reporting'),
                params: {
                    'LAYERS': 'Settlements_select',
                    'FILTER': writeFilterToString(equalTo('ogc_fid', '999999999999999'))
                },
            }),
        })
        lite.map.addLayer(reportRasterLayer);

        // REPORT BUTTON
        var reportButton = new Button({
          innerHTML: 'Generate report',
          className: 'report-button',
          title: 'Generate report',
          target: document.querySelector('#buttonBar')
        });
        lite.map.addControl(reportButton);

        reportButton.on('click', (evt) => {
            unByKey(queryClick);
            welcomePanel.hide();
            reportLayer.getSource().clear();
            drawTool.setActive(false);
            reportPanel.setMode('choose');
            reportPanel.show();
        });

        var reportPanel = new ReportPanel({
            target: sidebar
        });
        lite.map.addControl(reportPanel);

        // Custom report
        reportPanel.on('custom-draw', (evt) => {
            trackPageview({url: 'https://heatmap.data.gov.scot/reports/custom'});
            saveDemandState();
            reportLayer.getSource().clear();
            infoClick.setActive(false);
            drawTool.setActive(true);
            sidebar.classList.remove('sidebarOpen');
            map.classList.remove('sidebarOpen');
            lite.map.updateSize();
            sidebarButton.classList.add('active');
        });

        const drawTool = new Draw({
          source: reportLayer.getSource(),
          type: 'Polygon',
          freehandCondition: never,
          stopClick: true,
        });
        drawTool.setActive(false);
        lite.map.addInteraction(drawTool);

        drawTool.on('drawend', (evt) => {
          drawTool.setActive(false);
          reportPanel.setMode('custom-loading');
          document.querySelector('#sidebarButton').classList.add('pulse');
          const reportResult = fetchReport(evt.feature);
          reportResult.then(function (data) {
            reportPanel.setMode('custom-result', data);
            lite.map
              .getView()
              .fit(reportLayer.getSource().getExtent(), {
                duration: 1000,
                minResolution: 2,
                padding: [0, 0, window.innerHeight / 2, 0]
              });
          });
        });


        // Data Zone/Settlement reports
        let selectTarget = null;
        let queryClick = null;
        let areaField, demandField, densityField;

        reportPanel.on('Datazone-select', (evt) => {
            trackPageview({url: 'https://heatmap.data.gov.scot/reports/datazone'});
            selectTarget = 'Datazone';
            queryClick = enableSelectTool(selectTarget, reportLayer, reportPanel, infoClick, reportRasterLayer);
            sidebar.classList.remove('sidebarOpen');
            map.classList.remove('sidebarOpen');
            lite.map.updateSize();
            sidebarButton.classList.add('active');
        });

        reportPanel.on('Settlement-select', (evt) => {
            trackPageview({url: 'https://heatmap.data.gov.scot/reports/settlement'});
            selectTarget = 'Settlement';
            queryClick = enableSelectTool(selectTarget, reportLayer, reportPanel, infoClick, reportRasterLayer);
            sidebar.classList.remove('sidebarOpen');
            map.classList.remove('sidebarOpen');
            lite.map.updateSize();
            sidebarButton.classList.add('active');
        });

        reportPanel.on('LA-select', (evt) => {
            trackPageview({url: 'https://heatmap.data.gov.scot/reports/localauthority'});
            selectTarget = 'LA';
            queryClick = enableSelectTool(selectTarget, reportLayer, reportPanel, infoClick, reportRasterLayer);
            sidebar.classList.remove('sidebarOpen');
            map.classList.remove('sidebarOpen');
            lite.map.updateSize();
            sidebarButton.classList.add('active');
        });

        reportPanel.on('select-loading', (evt) => {
          console.log(2222222222, reportLayer.getSource().getFeatures());
            let features = reportLayer.getSource().getFeatures();
            if (features[0].get('area_km2')) {
              areaField = 'area_km2';
            } else {
              areaField = 'area_m2';
            }
            demandField = 'totalheatdemand_kwh_y';
            densityField = 'demanddensity_kwh_y_m2';
            if (features.length > 0) {
                let areaSum = sumProperties(features, areaField);
                if (areaField == 'area_km2') {
                  areaSum = areaSum * 1000000;
                }
                let demandSum = sumProperties(features, demandField);
                let supplySum = sumProperties(features, 'energy_supply_count');
                let publicSectorSum = sumProperties(features, 'public_sector_count');
                let demandDensity = demandSum / areaSum;
                var reportData = [features, areaSum, demandSum, supplySum, publicSectorSum, demandDensity];
                document.querySelector('#sidebarButton').classList.add('pulse');
                reportPanel.setMode(`${selectTarget}-select-result`, reportData);
            } else {
                unByKey(queryClick);
                reportPanel.setMode(`select-${selectTarget}`);
            }
        })
        
        reportPanel.on('print-report', (evt) => {
            let baselayerSource = getCurrentBaseLayer(lite.map).getSource();
            console.log(1, baselayerSource.getUrls()[0]);
            baselayerSource.setUrl('https://map.services.astuntechnology.com/open/osopen/service?map=');
            console.log(2, baselayerSource.getUrls()[0]);
            let openSource = baselayerSource.getParams().LAYERS.replace('Road_27700_Grayscale', 'osopenrasterbw');
            openSource = openSource.replace('Road_27700', 'osopenraster');
            console.log(3, openSource);
            getCurrentBaseLayer(lite.map).getSource().updateParams({'LAYERS': openSource});
            window.addEventListener('mouseover', postPrint, {once: true});
            window.addEventListener('focus', postPrint, {once: true});
            let coords = [];
            reportLayer.getSource().getFeatures().forEach((f) => {
                coords.push([f.get('boundedBy')[0], f.get('boundedBy')[1]]);
                coords.push([f.get('boundedBy')[2], f.get('boundedBy')[3]]);
            });
            let extent = boundingExtent(coords);
            map.classList.add('mapPrint');
            sidebar.classList.add('reportPrint');
            lite.map.updateSize();
            lite.map.getView().fit(
                extent,
                {
                    padding: [25, 25, 25, 25],
                    duration: 1000,
                    callback: function() {
                        lite.map.once(
                            'rendercomplete',
                            function() {
                                window.print();
                            }
                        )
                    }
                }
            );
        })

        reportPanel.on('print-custom-report', (evt) => {
            let baselayerSource = getCurrentBaseLayer(lite.map).getSource();
            console.log(4, baselayerSource.getUrls()[0]);
            baselayerSource.setUrl('https://map.services.astuntechnology.com/open/osopen/service?map=');
            console.log(5, baselayerSource.getUrls()[0]);
            let openSource = baselayerSource.getParams().LAYERS.replace('Road_27700_Grayscale', 'osopenrasterbw');
            openSource = openSource.replace('Road_27700', 'osopenraster');
            console.log(6, openSource);
            getCurrentBaseLayer(lite.map).getSource().updateParams({'LAYERS': openSource});
            window.addEventListener('mouseover', postPrint, {once: true});
            window.addEventListener('focus', postPrint, {once: true});
            map.classList.add('mapPrint');
            sidebar.classList.add('reportPrint');
            lite.map.updateSize();
            lite.map.getView().fit( 
                reportLayer.getSource().getExtent(),
                {
                    padding: [25, 25, 25, 25],
                    duration: 1000,
                    callback: function() {
                        lite.map.once(
                            'rendercomplete',
                            function() {
                                window.print();
                            }
                        )
                    }
                }
            );
        })

        reportPanel.on('clear-report', (evt) => {
            document.querySelector('#sidebarButton').classList.remove('pulse');
            reportLayer.getSource().clear();
            if (!drawTool.getActive()) {
                try {
                    restoreDemandState();
            } catch (e) {
            }
                drawTool.setActive(false);
            }
            unByKey(queryClick);
            infoClick.setActive(true);
            reportRasterLayer.getSource().updateParams({
                FILTER: writeFilterToString(equalTo('ogc_fid', '999999999999999'))
            });
        });
        
        reportPanel.on('choose', function (evt) {
            measureDisable();
            reportLayer.getSource().clear();
            drawTool.setActive(false);
        })

        reportPanel.on('custom-clear', function (evt) {
            drawTool.abortDrawing();
            drawTool.setActive(false);
            reportPanel.setMode('choose');
        })

        reportPanel.on('datazone-clear', function (evt) {
            reportPanel.dispatchEvent({type: 'clear-report'});
            reportPanel.setMode('choose');
        })

        reportPanel.on('settlement-clear', function (evt) {
            reportPanel.dispatchEvent({type: 'clear-report'});
            reportPanel.setMode('choose');
        })

        reportPanel.on('la-clear', function (evt) {
            reportPanel.dispatchEvent({type: 'clear-report'});
            reportPanel.setMode('choose');
        })

        reportPanel.on('close', function (evt) {
            reportLayer.getSource().clear();
            drawTool.setActive(false);
            reportPanel.setMode('choose');
            reportPanel.hide();
        })

        // UNCOLLAPSE ATTRIBUTION CONTROL
        lite.map.getControls().forEach(function(ctrl) {
            if (ctrl instanceof Attribution) {
                ctrl.setCollapsed(false);
                ctrl.setCollapsible(false);
            }
        })

        // Measure functionality

        function measureDisable() {
          measureLayer.getSource().clear();
          measureLength.setActive(false);
          measureArea.setActive(false);
          measureButton.setActive(false);
          measurePanel.hide();
          infoClick.setActive(true);
        }

        function measureEnable() {
            reportLayer.getSource().clear();
            drawTool.setActive(false);
            reportPanel.setMode('choose');
            reportPanel.hide();
            infoClick.setActive(false);
            measureLayer.getSource().clear();
            measureButton.setActive(true);
            measurePanel.show();
        }

        function getMeasureFeatureStyle(feature, resolution) {
          return [
            new Style({
              fill: new Fill({
                color: 'rgba(0, 0, 0, 0.125)',
              }),
              stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.5)',
                lineDash: [10, 10],
                width: 3,
              }),
            }),
            new Style({
              text: generateLabel(feature),
            }),
          ];
        }

        function getMeasurePointStyle(feature, resolution) {
          return new Style({
            image: new Circle({
              radius: 3,
              stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.5)',
                width: 2,
              }),
            }),
          });
        }

        function generateLabel(feature) {
          const label = generateMeasurementText(feature);
          if (label) {
            label.setFont('bold 12px sans-serif');
            label.setStroke(new Stroke({ width: 3, color: '#fff' }));
          }
          return label;
        }

        var measurePanel = new MeasurePanel({
            target: sidebar
        });
        lite.map.addControl(measurePanel);

        measurePanel.on('measure-panel-close', (evt) => {
          measureDisable();
        });

        measurePanel.on('measure-length', (evt) => {
            measureLayer.getSource().clear();
            measureLength.setActive(true);
            measureArea.setActive(false);
            sidebar.classList.remove('sidebarOpen');
            map.classList.remove('sidebarOpen');
            lite.map.updateSize();
            sidebarButton.classList.add('active');
        });

        measurePanel.on('measure-area', (evt) => {
            measureLayer.getSource().clear();
            measureLength.setActive(false);
            measureArea.setActive(true);
            sidebar.classList.remove('sidebarOpen');
            map.classList.remove('sidebarOpen');
            lite.map.updateSize();
            sidebarButton.classList.add('active');
        });

        measurePanel.on('close', function (evt) {
            this.handleCloseClick();
        })

        let measureLayer = new VectorLayer({
          source: new VectorSource(),
          style: getMeasureFeatureStyle
        });
        lite.map.addLayer(measureLayer);

        let measureLength = new MeasureInteraction({
          source: measureLayer.getSource(),
          type: 'LineString',
          persist: false
        });
        measureLength.setActive(false);
        lite.map.addInteraction(measureLength);

        measureLength.getOverlay().setStyle(function (feature, resolution) {
          const geomType = feature.getGeometry().getType();
          if (geomType === 'LineString') {
            return getMeasureFeatureStyle(feature, resolution);
          } else if (geomType === 'Point') {
            return getMeasurePointStyle(feature, resolution);
          }
        });

        measureLength.on('measurementfinal', (evt) => {
          measurePanel.setProps({
            length: evt.feature.get('formattedLength')
          });
        });

        measureLength.on('measurementdraft', (evt) => {
          measurePanel.setProps({
            length: evt.feature.get('formattedLength')
          });
        });

        let measureArea = new MeasureInteraction({
          source: measureLayer.getSource(),
          type: 'Polygon',
          persist: false
        });
        measureArea.setActive(false);
        lite.map.addInteraction(measureArea);

        measureArea.getOverlay().setStyle(function (feature, resolution) {
          const geomType = feature.getGeometry().getType();
          if (geomType === 'Polygon') {
            return getMeasureFeatureStyle(feature, resolution);
          } else if (geomType === 'Point') {
            return getMeasurePointStyle(feature, resolution);
          }
        });

        measureArea.on('measurementfinal', (evt) => {
          measurePanel.setProps({
            length: evt.feature.get('formattedPerimeter'),
            area: evt.feature.get('formattedArea')
          });
        });

        measureArea.on('measurementdraft', (evt) => {
          measurePanel.setProps({
            length: evt.feature.get('formattedPerimeter'),
            area: evt.feature.get('formattedArea')
          });
        });

        let measureButton = new Button({
          className: 'btn-measure',
          innerHTML: 'straighten',
          title: 'Measure tool',
          target: document.querySelector('#buttonBar'),
          active: false
        });
        lite.map.addControl(measureButton);

        measureButton.on('click', (evt) => {
          trackPageview({url: 'https://heatmap.data.gov.scot/tools/measure'});
          if (measureButton.getActive()) {
            measureDisable();
          } else {
            reportPanel.handleCloseClick()
            measureEnable();
          }
        });

        // PERMALINK

        let shareButton = new LinkButton({
          className: 'btn-share',
          innerHTML: 'share',
          target: document.querySelector('#buttonBar'),
          title: 'Copy link to current map to clipboard'
        });
        lite.map.addControl(shareButton);

        shareButton.getLink().addEventListener('click', (evt) => {
          trackPageview({url: 'https://heatmap.data.gov.scot/tools/permalink'});
          let copied = copy(urlStore.url);
          if (copied) {
            mktoast.info('A link to the current map has been copied to your clipboard.', {
              position: 'top left'
            });
          } else {
            mktoast.echo('Unable to copy link to clipboard, try right-clicking or long pressing the link.', {
              position: 'top left'
            });
          }
          evt.preventDefault();
        });

        let helpButton = new LinkButton({
          className: 'btn-help',
          innerHTML: 'mail_outline',
          target: document.querySelector('#buttonBar'),
          title: 'Email support'
        });
        helpButton.getLink().addEventListener('click', (evt) => {
          trackPageview({url: 'https://heatmap.data.gov.scot/tools/support'});
        })
        lite.map.addControl(helpButton);

        function updateHelpMailTo(mapUrl) {
            let mailto = helpButton.getLink();
            mailto.href =
              'mailto:heatmap@gov.scot?subject=Heat%20Map%20Support&body=' +
              encodeURIComponent(
                'Please describe your request for help in as much detail as possible. Including a link to your current view will help us investigate your issue. The share button on the map can be used to copy the link to your current view.'
              );
              mailto.target = '_blank';
        }

        shareButton.getLink().href = urlStore.url;
        updateHelpMailTo(urlStore.url);

        urlStore.on('updated', (evt) => {
            shareButton.getLink().href = evt.target.url;
            updateHelpMailTo(evt.target.url);
        });

        let docsButton = new LinkButton({
          className: 'btn-docs',
          innerHTML: 'help_outline',
          target: document.querySelector('#buttonBar'),
          title: 'Help'
        });
        lite.map.addControl(docsButton);
        docsButton.getLink().href = 'https://heatmap-data-gov-scot.readthedocs.io/en/latest/';
        docsButton.getLink().target = '_blank';
        docsButton.getLink().addEventListener('click', (evt) => {
          trackPageview({url: 'https://heatmap.data.gov.scot/tools/help'});
        });
        let resetButton = new LinkButton({
          className: 'btn-reset',
          innerHTML: 'restore_page',
          target: document.querySelector('#buttonBar'),
          title: 'Reset map'
        });
        lite.map.addControl(resetButton);
        resetButton.getLink().addEventListener('click', (evt) => {
          trackPageview({url: 'https://heatmap.data.gov.scot/tools/reset'});
          webStore.discard(LiteMapState.VALID_KEYS).then(function(response) {
              location.reload();
          });
          evt.preventDefault();
        }); 
       let printButton = new LinkButton({
          className: 'btn-print',
          innerHTML: 'print',
          target: document.querySelector('#buttonBar'),
          title: 'Print'
        });
        lite.map.addControl(printButton);
        printButton.getLink().href = 'javascript:window.print();';
        printButton.getLink().addEventListener('click', (evt) => {
          trackPageview({url: 'https://heatmap.data.gov.scot/tools/print'});
        })
 
        // HISTORY NAV
        var historyNavigation = new HistoryNavigation({
            // target: document.querySelector('#buttonBar'),
            backHtml: 'arrow_back',
            forwardHtml: 'arrow_forward'
        });
        lite.map.addControl(historyNavigation);
    });
});

var renderLayer = OlLayerSwitcher.renderLayer_;
OlLayerSwitcher.renderLayer_ = function (map, lyr, idx, options, render) {
    var li = renderLayer(map, lyr, idx, options, render);
    if (li.class != "group" && li.getElementsByTagName('input')[0].type == "checkbox" && li.className == "layer") {
        var optionsLink = document.createElement("a");
        optionsLink.href = "javascript:;";
        optionsLink.innerHTML = 'arrow_circle_right';
        var insertedLink = li.appendChild(optionsLink);
        insertedLink.addEventListener("click", () => {
            var panel = document.querySelector('.layerInfoPanel');
            LayerInfoPanel.showLayerInfo(lyr, map, panel, true, null);
        })
        let legendGraphic = li.querySelector('img.legend');
        if (legendGraphic && legendGraphic.src) {
            let legendGraphicSrc = legendGraphic.src;
            legendGraphic.parentNode.removeChild(legendGraphic);

            var div = document.createElement('div');
            div.classList.add('legendWrapper');
            legendGraphic = document.createElement('img');
            legendGraphic.classList.add('legend');
            // img.addEventListener('error', handleImageError);
            legendGraphic.src = legendGraphicSrc;
            div.appendChild(legendGraphic);
            var div2 = document.createElement('div');
            div2.classList.add('legendIcons');
            var legendIcons = document.createElement('img');
            legendIcons.id = `lgnd_${lyr.get('iShare:layerName')}`;
            legendIcons.classList.add('legend');
            // img2.addEventListener('error', handleImageError);
            legendIcons.src = legendGraphicSrc;
            legendIcons.style.opacity = lyr.getOpacity();
            div2.appendChild(legendIcons);
            div.appendChild(div2);
            li.appendChild(div);



            legendGraphic.id = `${lyr.get('iShare:layerName')}Legend`;
            if (getGroupContainingLayer(lite.map.getLayerGroup(), lyr).get('title' ) == "Energy Supply") {
                let lyrName = lyr.get('iShare:layerName');
                let legendGraphicSrc = legendGraphic.src + `&SLD=../custom/sld/${lyrName}.sld`;
                legendGraphic.src = legendGraphicSrc;
                legendIcons.src = legendGraphicSrc;
            }
        }
    }
    return li;
};

function fetchReport(feature) {
  const wkt = new WKT().writeGeometry(feature.getGeometry());
  var url = `${iShareUrl}/GetData.aspx?RequestType=xsl&Type=datafunc&xsl=xml/atJSONDatatable.xsl&ds=scotgov_heatdemand_polygon&params=${encodeURIComponent(
    wkt
  )}`;
  return fetch(url)
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      // There will only ever be a single row returned that represents the stats for
      // the passed geometry, simplify the structure of the data to key/ value pairs
      const stats = {};
      data.columns.forEach(function (col, idx) {
        let value = data.data[0][idx];
        // All values returned by the datafunc are primiative values, attempt to parse arrays
        if (
          typeof value === 'string' &&
          value.startsWith('[') &&
          value.endsWith(']')
        ) {
          try {
            value = JSON.parse(value);
          } catch (e) {
            // Ignore any error when attempting to parse as JSON
          }
        }
        stats[col] = value;
      });
      return stats;
    });
}

function enableSelectTool(queryLayerName, reportLayer, reportPanel, infoClick, reportRasterLayer) {
    reportLayer.getSource().clear();
    infoClick.setActive(false);

    let queryLayer, nonQueryLayers, boundaryLayer;
    if (queryLayerName == 'Settlement') {
        queryLayer = getLayerByName(lite.map.getLayerGroup(), 'Settlements_Heat_Demand');
        boundaryLayer = getLayerByName(lite.map.getLayerGroup(), 'Settlement');
        nonQueryLayers = [getLayerByName(lite.map.getLayerGroup(), 'Datazone'),
                          getLayerByName(lite.map.getLayerGroup(), 'Local_Authority')];
        reportRasterLayer.getSource().updateParams({
            'LAYERS': 'Settlements_select',
            FILTER: writeFilterToString(equalTo('ogc_fid', '999999999999999'))
        });
    } else if (queryLayerName == 'Datazone') {
        queryLayer = getLayerByName(lite.map.getLayerGroup(), 'Datazone_Heat_Demand');
        boundaryLayer = getLayerByName(lite.map.getLayerGroup(), 'Datazone');
        nonQueryLayers = [getLayerByName(lite.map.getLayerGroup(), 'Settlement'),
                         getLayerByName(lite.map.getLayerGroup(), 'Local_Authority')];
        reportRasterLayer.getSource().updateParams({
            'LAYERS': 'Datazone_select',
            FILTER: writeFilterToString(equalTo('ogc_fid', '999999999999999'))
        });
    } else {
        queryLayer = getLayerByName(lite.map.getLayerGroup(), 'Local_Authority_Demand');
        boundaryLayer = getLayerByName(lite.map.getLayerGroup(), 'Local_Authority');
        nonQueryLayers = [getLayerByName(lite.map.getLayerGroup(), 'Settlement'),
                         getLayerByName(lite.map.getLayerGroup(), 'Datazone')];
        reportRasterLayer.getSource().updateParams({
            'LAYERS': 'Local_Authority_select',
            FILTER: writeFilterToString(equalTo('ogc_fid', '999999999999999'))
        });
    }
    
    // Save current visibility of Heat Demand group layers
    saveDemandState();

    // Show relevant heat demand layer, hide others
    boundaryLayer.setVisible(true);
    reportRasterLayer.setVisible(true);
    queryLayer.setOpacity(0.75);
    getGroupContainingLayer(
        lite.map.getLayerGroup(), 
        getLayerByName(
            lite.map.getLayerGroup(),
            'Local_Authority'
        )
    ).setVisible(true);
    nonQueryLayers.forEach(function(nonQueryLayer) {
        nonQueryLayer.setVisible(false);
    });
    // getLayerByName(lite.map.getLayerGroup(), 'heat_demand').setVisible(false);

    let queryClick = lite.map.on('singleclick', function(e) {
        getInfoAtPoint(lite.map, [queryLayer], e.coordinate)
            .then(function(response) {
                let selectedIds = reportLayer.getSource().getFeatures().map((f) => f.get('ogc_fid'));;
                if (response.collections[0][0]) {
                    let id = response.collections[0][0].get('ogc_fid');
                    if (selectedIds.includes(id)) {
                        let selectedFeatures = reportLayer.getSource().getFeatures();
                        selectedFeatures.forEach(function(feature) {
                            if (feature.getProperties()['ogc_fid'] == id) {
                                reportLayer.getSource().removeFeature(feature);
                            };
                        })
                        selectedIds.remove(id);
                    } else {
                        selectedIds.push(id);
                        reportLayer.getSource().addFeature(response.collections[0][0]);
                    }
                }
                let filters = [];
                selectedIds.forEach(function(f) {
                   let filter = equalTo(
                        'ogc_fid',
                        f
                    );
                    filters.push(filter);
                })
                let filterStr = '';
                let ogcFilter = equalTo(
                    'ogc_fid',
                    '999999999'
                );
                if (filters.length > 1) {
                    ogcFilter = or.apply(null, filters);
                } else if (filters.length == 1) {
                    ogcFilter = filters[0];
                }
                filterStr = writeFilterToString(ogcFilter);
                reportRasterLayer.getSource().updateParams({
                    FILTER: filterStr
                });
                reportPanel.setMode('select-loading');
           });
    });
    return queryClick;
}

Array.prototype.remove = function(value) {
    for (var i = this.length; i--; )
    {
        if (this[i] === value) {
            this.splice(i, 1);
        }
    }
}

function writeFilterToString(filter) {
    var node = writeFilter(filter, '1.0.0');
    var str = new XMLSerializer().serializeToString(node);
    // Update the xmlns to be compatible with iShare v5.8 and below
    str = str.replace(
      'xmlns="http://www.opengis.net/ogc"',
      'xmlns:ogc="http://www.opengis.net/ogc"'
    );
    return str;
}

// Return the sum of property prop of all objects in array arr
function sumProperties(arr, prop) {
    return arr.map(a => parseFloat(a.get(prop))).reduce(
        function(a, b) {
            if (a + b) {
                return a + b;
            } else {
                return a;
            }
        }
    );
}

let demandState = {};

function saveDemandState() {
    getGroupContainingLayer(
        lite.map.getLayerGroup(), 
        getLayerByName(
            lite.map.getLayerGroup(),
            'Local_Authority'
        )
    ).getLayers().forEach(
        function(lyr,idx,arr) {
            demandState[lyr.get('iShare:layerName')] = {
                'visible': lyr.getVisible(),
                'opacity': lyr.getOpacity()
            }
        }
    )
}

function restoreDemandState() {
    getGroupContainingLayer(
        lite.map.getLayerGroup(), 
        getLayerByName(
            lite.map.getLayerGroup(),
            'Local_Authority'
        )
    ).getLayers().forEach(
        function(lyr,idx,arr) {
            lyr.setVisible(demandState[lyr.get('iShare:layerName')].visible);
            lyr.setOpacity(parseFloat(demandState[lyr.get('iShare:layerName')].opacity));
        }
    )
}

function postPrint(evt) {
    let baselayerSource = getCurrentBaseLayer(lite.map).getSource();
    console.log(7, baselayerSource.getUrls()[0]);
    baselayerSource.setUrl('https://map.services.astuntechnology.com/gov-scot/osmaps/service?key=KawM9ZyET6VCFfTTouGKYwz1iOfVspLG');
    console.log(8, baselayerSource.getUrls()[0]);
    let premiumSource = baselayerSource.getParams().LAYERS.replace('osopenrasterbw', 'Road_27700_Grayscale');
    premiumSource = premiumSource.replace('osopenraster', 'Road_27700');
    console.log(9, premiumSource);
    getCurrentBaseLayer(lite.map).getSource().updateParams({'LAYERS': premiumSource});

    map.classList.remove('mapPrint');
    sidebar.classList.remove('reportPrint');
    lite.map.updateSize();
}
